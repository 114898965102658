import Vue from 'vue';
import { TYPE_ACTION_MEDIA_MANAGER } from '@/constants';
import menuManagerGalleryUtil from '@/utils/menuManagerGallery.js';
import STORE from '@/store/types';

export default Vue.extend({
  name: 'MediaDescriptionPanoramics',

  data: () => ({
    actionDeleteImage: TYPE_ACTION_MEDIA_MANAGER.DELETE_IMAGE,
    renderPanoramics: true
  }),

  components: {
    PanoramicVideo: () => import('@components/generals/MediaDescription/PanoramicVideo'),
    MediaManager: () => import('@components/generals/MediaDescription/MediaManager')
  },

  computed: {
    getRenderPanoramicStore() {
      return this.$store.getters[STORE.GETTERS.ALBUMS.GET_RENDER_PANRAMICS];
    },

    getPanoramicsStore() {
      return this.$store.getters[STORE.GETTERS.ALBUMS.GET_PANORAMICS];
    }
  },

  methods: {
    handlerMediaManager(id) {
      menuManagerGalleryUtil(id);
    }
  }
});
